@mixin link() {
  font-weight: $medium;
  color: $purple;
  transition: color .2s ease-in-out;
  word-break: break-word;
  .icon {
    display: inline-block;
    background: $purple;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    line-height: 27px;
    text-align: center;
    margin-right: 5px;
    color: #fff;
    transition: background .2s ease-in-out;
  }
  &:focus,
  &:hover {
    color: $purple-700;
    text-decoration: none;
    .icon {
      background: $purple-700;
    }
  }
}

@mixin linkArrow() {
  &::after {
    display: inline-block;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent currentColor;
    margin-left: $space / 2.5;
    margin-right: $space / 2.5;
    transition: border-color .1s ease-in-out;
    vertical-align: middle;
  }
}

@mixin linkBlue() {
  color: $blue;
  &::after {
    border-color: transparent transparent transparent $blue;
  }
  &:focus,
  &:hover {
    color: $blue-500;

    &::after {
      border-color: transparent transparent transparent $blue-500;
    }
  }
}

@mixin linkGreen() {
  color: $green;
  &::after {
    border-color: transparent transparent transparent $green;
  }
  &:focus,
  &:hover {
    color: $green-100;

    &::after {
      border-color: transparent transparent transparent $green-100;
    }
  }
}

@mixin linkWhite() {
  color: $white;
  &::after {
    border-color: transparent transparent transparent $white;
  }
  &:focus,
  &:hover {
    color: $grey-200;

    &::after {
      border-color: transparent transparent transparent $grey-200;
    }
  }
}