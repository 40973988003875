@charset "UTF-8";
/*
$flogasThemeColours are available via gutenberg and may be applied as foreground or background colors
 */
/*
 Set Bootstrap defaults
*/
/*
 Grid / container
*/
/*
 Typography
*/
/*
 Links
*/
/*
 Inputs
*/
/*
 Misc
*/
.image-testimonial {
  width: 100%;
  position: relative;
  background: #4C2177;
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .image-testimonial {
      min-height: 150px; } }
  .image-testimonial.image-testimonial--module {
    margin-bottom: 64px; }
    @media (max-width: 991.98px) {
      .image-testimonial.image-testimonial--module {
        margin-bottom: 32px; } }
    @media screen and (max-height: 768px) {
      .image-testimonial.image-testimonial--module {
        margin-bottom: 32px; } }
  .image-testimonial__quote {
    color: #191919; }
    .image-testimonial__quote-text {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      font-style: italic;
      max-width: 466px; }
      .image-testimonial__quote-text p:first-of-type:before {
        content: "“";
        display: inline-block; }
      .image-testimonial__quote-text p:last-of-type:after {
        content: "”";
        display: inline-block; }
    .image-testimonial__quote-author {
      font-size: 16px;
      line-height: 24px;
      color: #1A1A1A;
      font-weight: 600; }
      .image-testimonial__quote-author:before {
        content: "";
        display: block;
        width: 64px;
        height: 1px;
        background: #191919;
        margin-bottom: 24px;
        margin-top: 24px; }

#flogas-content .image-testimonial {
  width: 100vw;
  left: 50%;
  transform: translatex(-50%); }

.image-testimonial__background {
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, 0); }
  @media (max-width: 767.98px) {
    .image-testimonial__background {
      min-height: 220px; } }

.image-testimonial__background-image {
  text-align: left;
  position: relative;
  height: 100%;
  margin-left: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .image-testimonial__background-image:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 60%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0) 100%); }
    @media (max-width: 991.98px) {
      .image-testimonial__background-image:after {
        width: 100%;
        background: rgba(255, 255, 255, 0.8); } }

.image-testimonial__inner {
  position: relative;
  z-index: 2;
  overflow: hidden;
  color: #FFF;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  .image-testimonial__inner .link-inline, .image-testimonial__inner a:not(.button) {
    color: #FFF;
    transition: all ease-in-out 0.2s; }
    .image-testimonial__inner .link-inline:after, .image-testimonial__inner a:not(.button):after {
      transition: all ease-in-out 0.2s; }
    .image-testimonial__inner .link-inline:hover, .image-testimonial__inner a:not(.button):hover {
      opacity: 0.7; }
  .image-testimonial__inner h1, .image-testimonial__inner h2, .image-testimonial__inner h3, .image-testimonial__inner h4, .image-testimonial__inner h5, .image-testimonial__inner h6, .image-testimonial__inner span.small {
    margin: 0 0 16px 0;
    color: #FFF; }
  .image-testimonial__inner h4, .image-testimonial__inner h5, .image-testimonial__inner h6 {
    font-weight: 400; }
  @media (min-width: 576px) {
    .image-testimonial__inner {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .image-testimonial__inner {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .image-testimonial__inner {
      max-width: 960px; } }
  @media (min-width: 1284px) {
    .image-testimonial__inner {
      max-width: 1224px; } }
  .image-testimonial__inner h1, .image-testimonial__inner span.small {
    font-size: 1.5rem; }
  .image-testimonial__inner h2, .image-testimonial__inner h1.large {
    font-weight: 500;
    font-size: 2.5rem; }
    @media (max-width: 767.98px) {
      .image-testimonial__inner h2, .image-testimonial__inner h1.large {
        font-size: 1.5rem; } }
  @media (min-width: 768px) {
    .image-testimonial__inner {
      min-height: 200px;
      z-index: 3; } }
  @media (max-width: 767.98px) {
    .image-testimonial__inner {
      width: 100%;
      max-width: none;
      padding: 20px; } }
  @media (max-width: 575.98px) {
    .image-testimonial__inner {
      padding: 20px 22.5px; } }
  @media screen and (max-height: 768px) {
    .image-testimonial__inner {
      padding-bottom: 32px; } }

.image-testimonial__inner-content {
  margin: 16px 0 32px 0; }
  @media (min-width: 768px) {
    .image-testimonial__inner-content {
      width: 60%; } }
  @media (min-width: 992px) and (max-width: 1283.98px) {
    .image-testimonial__inner-content {
      margin: 80px 0;
      padding-right: 150px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .image-testimonial__inner-content {
      margin: 62px 0;
      width: 60%; }
      .image-testimonial__inner-content.has-breadcrumbs {
        margin-top: 55px; } }
  @media (min-width: 1284px) {
    .image-testimonial__inner-content {
      margin: 81px 0 80px 0; } }
  @media (max-width: 320px) {
    .image-testimonial__inner-content {
      max-width: 510px;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 321px) and (max-width: 767px) {
    .image-testimonial__inner-content {
      max-width: 510px;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-height: 768px) {
    .image-testimonial__inner-content {
      margin: 32px 0; } }
  .image-testimonial__inner-content > p > a {
    font-weight: 500;
    color: #4C2177;
    transition: color .2s ease-in-out;
    word-break: break-word;
    color: #FFF; }
    .image-testimonial__inner-content > p > a .icon {
      display: inline-block;
      background: #4C2177;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      line-height: 27px;
      text-align: center;
      margin-right: 5px;
      color: #fff;
      transition: background .2s ease-in-out; }
    .image-testimonial__inner-content > p > a:focus, .image-testimonial__inner-content > p > a:hover {
      color: #81639F;
      text-decoration: none; }
      .image-testimonial__inner-content > p > a:focus .icon, .image-testimonial__inner-content > p > a:hover .icon {
        background: #81639F; }
    .image-testimonial__inner-content > p > a::after {
      border-color: transparent transparent transparent #FFF; }
    .image-testimonial__inner-content > p > a:focus, .image-testimonial__inner-content > p > a:hover {
      color: #CCC; }
      .image-testimonial__inner-content > p > a:focus::after, .image-testimonial__inner-content > p > a:hover::after {
        border-color: transparent transparent transparent #CCC; }
  .image-testimonial__inner-content .breadcrumbs {
    margin-bottom: 20px;
    border: none; }
    .image-testimonial__inner-content .breadcrumbs .container {
      padding: 0; }
    .image-testimonial__inner-content .breadcrumbs li {
      color: rgba(255, 255, 255, 0.5); }
      .image-testimonial__inner-content .breadcrumbs li a {
        color: #fff; }
  .image-testimonial__inner-content .flogas-columns {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    width: auto;
    left: auto;
    right: auto; }
  .image-testimonial__inner-content > div, .image-testimonial__inner-content > p {
    margin-bottom: 25px; }
  .image-testimonial__inner-content .wp-block-flogas-buttons {
    margin-bottom: 0px; }
    .image-testimonial__inner-content .wp-block-flogas-buttons .wp-block-flogas-button {
      padding-bottom: 15px; }
  .image-testimonial__inner-content > *:last-child {
    margin-bottom: 1px; }

@media (max-width: 767.98px) {
  .image-testimonial--blue .image-testimonial__background,
  .image-testimonial--blue-secondary .image-testimonial__background,
  .image-testimonial--green-dark .image-testimonial__background,
  .image-testimonial--black .image-testimonial__background,
  .image-testimonial--purple .image-testimonial__background {
    position: static;
    transform: translate(0); } }

.image-testimonial .button--white {
  background-color: #FFF;
  border-color: #FFF; }
  .image-testimonial .button--white .button__inner span {
    color: #1A1A1A; }
  .image-testimonial .button--white .button__inner svg *, .image-testimonial .button--white .button__inner svg .flogas-icon--foreground {
    fill: #1A1A1A; }
  .image-testimonial .button--white:focus, .image-testimonial .button--white:hover, .image-testimonial .button--white:before {
    background-color: #CCC;
    color: #1A1A1A; }
  .image-testimonial .button--white.button--secondary .button__inner {
    color: #FFF; }
    .image-testimonial .button--white.button--secondary .button__inner span {
      color: #FFF; }
    .image-testimonial .button--white.button--secondary .button__inner svg *, .image-testimonial .button--white.button--secondary .button__inner svg .flogas-icon--foreground {
      fill: #FFF; }
  .image-testimonial .button--white.button--secondary:after {
    border-color: #FFF; }
  .image-testimonial .button--white.button--secondary:before {
    background-color: #CCC; }
  .image-testimonial .button--white.button--secondary:focus, .image-testimonial .button--white.button--secondary:hover {
    background-color: #CCC;
    border-color: #CCC; }
    .image-testimonial .button--white.button--secondary:focus span, .image-testimonial .button--white.button--secondary:hover span {
      color: #1A1A1A; }
    .image-testimonial .button--white.button--secondary:focus svg *, .image-testimonial .button--white.button--secondary:focus svg .flogas-icon--foreground, .image-testimonial .button--white.button--secondary:hover svg *, .image-testimonial .button--white.button--secondary:hover svg .flogas-icon--foreground {
      fill: #1A1A1A; }

.image-testimonial--purple .image-testimonial__background {
  background: #4C2177; }

.image-testimonial--purple .button.button--purple,
.image-testimonial--purple .button.button--white,
.image-testimonial--purple .button.button--green {
  background-color: #00B3CF; }
  .image-testimonial--purple .button.button--purple .button__inner span,
  .image-testimonial--purple .button.button--white .button__inner span,
  .image-testimonial--purple .button.button--green .button__inner span {
    color: #FFF; }
  .image-testimonial--purple .button.button--purple .button__inner svg *, .image-testimonial--purple .button.button--purple .button__inner svg .flogas-icon--foreground,
  .image-testimonial--purple .button.button--white .button__inner svg *,
  .image-testimonial--purple .button.button--white .button__inner svg .flogas-icon--foreground,
  .image-testimonial--purple .button.button--green .button__inner svg *,
  .image-testimonial--purple .button.button--green .button__inner svg .flogas-icon--foreground {
    fill: #FFF; }
  .image-testimonial--purple .button.button--purple:focus, .image-testimonial--purple .button.button--purple:hover, .image-testimonial--purple .button.button--purple:before,
  .image-testimonial--purple .button.button--white:focus,
  .image-testimonial--purple .button.button--white:hover,
  .image-testimonial--purple .button.button--white:before,
  .image-testimonial--purple .button.button--green:focus,
  .image-testimonial--purple .button.button--green:hover,
  .image-testimonial--purple .button.button--green:before {
    background-color: #7FD9E7;
    color: #FFF; }
  .image-testimonial--purple .button.button--purple.button--secondary .button__inner,
  .image-testimonial--purple .button.button--white.button--secondary .button__inner,
  .image-testimonial--purple .button.button--green.button--secondary .button__inner {
    color: #FFF; }
    .image-testimonial--purple .button.button--purple.button--secondary .button__inner span,
    .image-testimonial--purple .button.button--white.button--secondary .button__inner span,
    .image-testimonial--purple .button.button--green.button--secondary .button__inner span {
      color: #FFF; }
    .image-testimonial--purple .button.button--purple.button--secondary .button__inner svg *, .image-testimonial--purple .button.button--purple.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--purple .button.button--white.button--secondary .button__inner svg *,
    .image-testimonial--purple .button.button--white.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--purple .button.button--green.button--secondary .button__inner svg *,
    .image-testimonial--purple .button.button--green.button--secondary .button__inner svg .flogas-icon--foreground {
      fill: #FFF; }
  .image-testimonial--purple .button.button--purple.button--secondary:after,
  .image-testimonial--purple .button.button--white.button--secondary:after,
  .image-testimonial--purple .button.button--green.button--secondary:after {
    border-color: #FFF; }
  .image-testimonial--purple .button.button--purple.button--secondary:before,
  .image-testimonial--purple .button.button--white.button--secondary:before,
  .image-testimonial--purple .button.button--green.button--secondary:before {
    background-color: #CCC; }
  .image-testimonial--purple .button.button--purple.button--secondary:focus, .image-testimonial--purple .button.button--purple.button--secondary:hover,
  .image-testimonial--purple .button.button--white.button--secondary:focus,
  .image-testimonial--purple .button.button--white.button--secondary:hover,
  .image-testimonial--purple .button.button--green.button--secondary:focus,
  .image-testimonial--purple .button.button--green.button--secondary:hover {
    background-color: #CCC;
    border-color: #CCC; }
    .image-testimonial--purple .button.button--purple.button--secondary:focus span, .image-testimonial--purple .button.button--purple.button--secondary:hover span,
    .image-testimonial--purple .button.button--white.button--secondary:focus span,
    .image-testimonial--purple .button.button--white.button--secondary:hover span,
    .image-testimonial--purple .button.button--green.button--secondary:focus span,
    .image-testimonial--purple .button.button--green.button--secondary:hover span {
      color: #1A1A1A; }
    .image-testimonial--purple .button.button--purple.button--secondary:focus svg *, .image-testimonial--purple .button.button--purple.button--secondary:focus svg .flogas-icon--foreground, .image-testimonial--purple .button.button--purple.button--secondary:hover svg *, .image-testimonial--purple .button.button--purple.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--purple .button.button--white.button--secondary:focus svg *,
    .image-testimonial--purple .button.button--white.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--purple .button.button--white.button--secondary:hover svg *,
    .image-testimonial--purple .button.button--white.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--purple .button.button--green.button--secondary:focus svg *,
    .image-testimonial--purple .button.button--green.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--purple .button.button--green.button--secondary:hover svg *,
    .image-testimonial--purple .button.button--green.button--secondary:hover svg .flogas-icon--foreground {
      fill: #1A1A1A; }

.image-testimonial--purple .has-purple-color {
  color: #A590BB; }

.image-testimonial--green-dark {
  background: #007A33; }
  .image-testimonial--green-dark .image-testimonial__background {
    background: #007A33; }
  .image-testimonial--green-dark .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--mobile,
  .image-testimonial--green-dark .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--foreground {
    fill: #007A33; }
  .image-testimonial--green-dark .button.button--purple,
  .image-testimonial--green-dark .button.button--white,
  .image-testimonial--green-dark .button.button--blue {
    background-color: #FFF;
    border-color: #FFF; }
    .image-testimonial--green-dark .button.button--purple .button__inner span,
    .image-testimonial--green-dark .button.button--white .button__inner span,
    .image-testimonial--green-dark .button.button--blue .button__inner span {
      color: #1A1A1A; }
    .image-testimonial--green-dark .button.button--purple .button__inner svg *, .image-testimonial--green-dark .button.button--purple .button__inner svg .flogas-icon--foreground,
    .image-testimonial--green-dark .button.button--white .button__inner svg *,
    .image-testimonial--green-dark .button.button--white .button__inner svg .flogas-icon--foreground,
    .image-testimonial--green-dark .button.button--blue .button__inner svg *,
    .image-testimonial--green-dark .button.button--blue .button__inner svg .flogas-icon--foreground {
      fill: #1A1A1A; }
    .image-testimonial--green-dark .button.button--purple:focus, .image-testimonial--green-dark .button.button--purple:hover, .image-testimonial--green-dark .button.button--purple:before,
    .image-testimonial--green-dark .button.button--white:focus,
    .image-testimonial--green-dark .button.button--white:hover,
    .image-testimonial--green-dark .button.button--white:before,
    .image-testimonial--green-dark .button.button--blue:focus,
    .image-testimonial--green-dark .button.button--blue:hover,
    .image-testimonial--green-dark .button.button--blue:before {
      background-color: #CCC;
      color: #1A1A1A; }
    .image-testimonial--green-dark .button.button--purple.button--secondary .button__inner,
    .image-testimonial--green-dark .button.button--white.button--secondary .button__inner,
    .image-testimonial--green-dark .button.button--blue.button--secondary .button__inner {
      color: #FFF; }
      .image-testimonial--green-dark .button.button--purple.button--secondary .button__inner span,
      .image-testimonial--green-dark .button.button--white.button--secondary .button__inner span,
      .image-testimonial--green-dark .button.button--blue.button--secondary .button__inner span {
        color: #FFF; }
      .image-testimonial--green-dark .button.button--purple.button--secondary .button__inner svg *, .image-testimonial--green-dark .button.button--purple.button--secondary .button__inner svg .flogas-icon--foreground,
      .image-testimonial--green-dark .button.button--white.button--secondary .button__inner svg *,
      .image-testimonial--green-dark .button.button--white.button--secondary .button__inner svg .flogas-icon--foreground,
      .image-testimonial--green-dark .button.button--blue.button--secondary .button__inner svg *,
      .image-testimonial--green-dark .button.button--blue.button--secondary .button__inner svg .flogas-icon--foreground {
        fill: #FFF; }
    .image-testimonial--green-dark .button.button--purple.button--secondary:after,
    .image-testimonial--green-dark .button.button--white.button--secondary:after,
    .image-testimonial--green-dark .button.button--blue.button--secondary:after {
      border-color: #FFF; }
    .image-testimonial--green-dark .button.button--purple.button--secondary:before,
    .image-testimonial--green-dark .button.button--white.button--secondary:before,
    .image-testimonial--green-dark .button.button--blue.button--secondary:before {
      background-color: #CCC; }
    .image-testimonial--green-dark .button.button--purple.button--secondary:focus, .image-testimonial--green-dark .button.button--purple.button--secondary:hover,
    .image-testimonial--green-dark .button.button--white.button--secondary:focus,
    .image-testimonial--green-dark .button.button--white.button--secondary:hover,
    .image-testimonial--green-dark .button.button--blue.button--secondary:focus,
    .image-testimonial--green-dark .button.button--blue.button--secondary:hover {
      background-color: #CCC;
      border-color: #CCC; }
      .image-testimonial--green-dark .button.button--purple.button--secondary:focus span, .image-testimonial--green-dark .button.button--purple.button--secondary:hover span,
      .image-testimonial--green-dark .button.button--white.button--secondary:focus span,
      .image-testimonial--green-dark .button.button--white.button--secondary:hover span,
      .image-testimonial--green-dark .button.button--blue.button--secondary:focus span,
      .image-testimonial--green-dark .button.button--blue.button--secondary:hover span {
        color: #1A1A1A; }
      .image-testimonial--green-dark .button.button--purple.button--secondary:focus svg *, .image-testimonial--green-dark .button.button--purple.button--secondary:focus svg .flogas-icon--foreground, .image-testimonial--green-dark .button.button--purple.button--secondary:hover svg *, .image-testimonial--green-dark .button.button--purple.button--secondary:hover svg .flogas-icon--foreground,
      .image-testimonial--green-dark .button.button--white.button--secondary:focus svg *,
      .image-testimonial--green-dark .button.button--white.button--secondary:focus svg .flogas-icon--foreground,
      .image-testimonial--green-dark .button.button--white.button--secondary:hover svg *,
      .image-testimonial--green-dark .button.button--white.button--secondary:hover svg .flogas-icon--foreground,
      .image-testimonial--green-dark .button.button--blue.button--secondary:focus svg *,
      .image-testimonial--green-dark .button.button--blue.button--secondary:focus svg .flogas-icon--foreground,
      .image-testimonial--green-dark .button.button--blue.button--secondary:hover svg *,
      .image-testimonial--green-dark .button.button--blue.button--secondary:hover svg .flogas-icon--foreground {
        fill: #1A1A1A; }
  .image-testimonial--green-dark .has-green-dark-color {
    color: #78BE21; }

.image-testimonial--blue {
  background: #00B3CF; }
  .image-testimonial--blue .image-testimonial__background {
    background: #00B3CF; }
  .image-testimonial--blue .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--mobile,
  .image-testimonial--blue .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--foreground {
    fill: #00B3CF; }
  .image-testimonial--blue .has-blue-color {
    color: #99E0EB; }

.image-testimonial--blue-secondary {
  background: #00A9E0; }
  .image-testimonial--blue-secondary .image-testimonial__background {
    background: #00A9E0; }
  .image-testimonial--blue-secondary .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--mobile,
  .image-testimonial--blue-secondary .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--foreground {
    fill: #00A9E0; }

.image-testimonial--black {
  background: #040505; }
  .image-testimonial--black .image-testimonial__background {
    background: #040505; }
  .image-testimonial--black .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--mobile,
  .image-testimonial--black .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--foreground {
    fill: #040505; }

.image-testimonial--special-offer .image-testimonial__background-image {
  background: #FFF; }

.image-testimonial--blue .button.button--blue,
.image-testimonial--blue .button.button--white,
.image-testimonial--blue .button.button--green,
.image-testimonial--blue-secondary .button.button--blue,
.image-testimonial--blue-secondary .button.button--white,
.image-testimonial--blue-secondary .button.button--green {
  background-color: #FFF;
  border-color: #FFF; }
  .image-testimonial--blue .button.button--blue .button__inner span,
  .image-testimonial--blue .button.button--white .button__inner span,
  .image-testimonial--blue .button.button--green .button__inner span,
  .image-testimonial--blue-secondary .button.button--blue .button__inner span,
  .image-testimonial--blue-secondary .button.button--white .button__inner span,
  .image-testimonial--blue-secondary .button.button--green .button__inner span {
    color: #1A1A1A; }
  .image-testimonial--blue .button.button--blue .button__inner svg *, .image-testimonial--blue .button.button--blue .button__inner svg .flogas-icon--foreground,
  .image-testimonial--blue .button.button--white .button__inner svg *,
  .image-testimonial--blue .button.button--white .button__inner svg .flogas-icon--foreground,
  .image-testimonial--blue .button.button--green .button__inner svg *,
  .image-testimonial--blue .button.button--green .button__inner svg .flogas-icon--foreground,
  .image-testimonial--blue-secondary .button.button--blue .button__inner svg *,
  .image-testimonial--blue-secondary .button.button--blue .button__inner svg .flogas-icon--foreground,
  .image-testimonial--blue-secondary .button.button--white .button__inner svg *,
  .image-testimonial--blue-secondary .button.button--white .button__inner svg .flogas-icon--foreground,
  .image-testimonial--blue-secondary .button.button--green .button__inner svg *,
  .image-testimonial--blue-secondary .button.button--green .button__inner svg .flogas-icon--foreground {
    fill: #1A1A1A; }
  .image-testimonial--blue .button.button--blue:focus, .image-testimonial--blue .button.button--blue:hover, .image-testimonial--blue .button.button--blue:before,
  .image-testimonial--blue .button.button--white:focus,
  .image-testimonial--blue .button.button--white:hover,
  .image-testimonial--blue .button.button--white:before,
  .image-testimonial--blue .button.button--green:focus,
  .image-testimonial--blue .button.button--green:hover,
  .image-testimonial--blue .button.button--green:before,
  .image-testimonial--blue-secondary .button.button--blue:focus,
  .image-testimonial--blue-secondary .button.button--blue:hover,
  .image-testimonial--blue-secondary .button.button--blue:before,
  .image-testimonial--blue-secondary .button.button--white:focus,
  .image-testimonial--blue-secondary .button.button--white:hover,
  .image-testimonial--blue-secondary .button.button--white:before,
  .image-testimonial--blue-secondary .button.button--green:focus,
  .image-testimonial--blue-secondary .button.button--green:hover,
  .image-testimonial--blue-secondary .button.button--green:before {
    background-color: #CCC;
    color: #1A1A1A; }
  .image-testimonial--blue .button.button--blue.button--secondary .button__inner,
  .image-testimonial--blue .button.button--white.button--secondary .button__inner,
  .image-testimonial--blue .button.button--green.button--secondary .button__inner,
  .image-testimonial--blue-secondary .button.button--blue.button--secondary .button__inner,
  .image-testimonial--blue-secondary .button.button--white.button--secondary .button__inner,
  .image-testimonial--blue-secondary .button.button--green.button--secondary .button__inner {
    color: #FFF; }
    .image-testimonial--blue .button.button--blue.button--secondary .button__inner span,
    .image-testimonial--blue .button.button--white.button--secondary .button__inner span,
    .image-testimonial--blue .button.button--green.button--secondary .button__inner span,
    .image-testimonial--blue-secondary .button.button--blue.button--secondary .button__inner span,
    .image-testimonial--blue-secondary .button.button--white.button--secondary .button__inner span,
    .image-testimonial--blue-secondary .button.button--green.button--secondary .button__inner span {
      color: #FFF; }
    .image-testimonial--blue .button.button--blue.button--secondary .button__inner svg *, .image-testimonial--blue .button.button--blue.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--blue .button.button--white.button--secondary .button__inner svg *,
    .image-testimonial--blue .button.button--white.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--blue .button.button--green.button--secondary .button__inner svg *,
    .image-testimonial--blue .button.button--green.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--blue-secondary .button.button--blue.button--secondary .button__inner svg *,
    .image-testimonial--blue-secondary .button.button--blue.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--blue-secondary .button.button--white.button--secondary .button__inner svg *,
    .image-testimonial--blue-secondary .button.button--white.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--blue-secondary .button.button--green.button--secondary .button__inner svg *,
    .image-testimonial--blue-secondary .button.button--green.button--secondary .button__inner svg .flogas-icon--foreground {
      fill: #FFF; }
  .image-testimonial--blue .button.button--blue.button--secondary:after,
  .image-testimonial--blue .button.button--white.button--secondary:after,
  .image-testimonial--blue .button.button--green.button--secondary:after,
  .image-testimonial--blue-secondary .button.button--blue.button--secondary:after,
  .image-testimonial--blue-secondary .button.button--white.button--secondary:after,
  .image-testimonial--blue-secondary .button.button--green.button--secondary:after {
    border-color: #FFF; }
  .image-testimonial--blue .button.button--blue.button--secondary:before,
  .image-testimonial--blue .button.button--white.button--secondary:before,
  .image-testimonial--blue .button.button--green.button--secondary:before,
  .image-testimonial--blue-secondary .button.button--blue.button--secondary:before,
  .image-testimonial--blue-secondary .button.button--white.button--secondary:before,
  .image-testimonial--blue-secondary .button.button--green.button--secondary:before {
    background-color: #CCC; }
  .image-testimonial--blue .button.button--blue.button--secondary:focus, .image-testimonial--blue .button.button--blue.button--secondary:hover,
  .image-testimonial--blue .button.button--white.button--secondary:focus,
  .image-testimonial--blue .button.button--white.button--secondary:hover,
  .image-testimonial--blue .button.button--green.button--secondary:focus,
  .image-testimonial--blue .button.button--green.button--secondary:hover,
  .image-testimonial--blue-secondary .button.button--blue.button--secondary:focus,
  .image-testimonial--blue-secondary .button.button--blue.button--secondary:hover,
  .image-testimonial--blue-secondary .button.button--white.button--secondary:focus,
  .image-testimonial--blue-secondary .button.button--white.button--secondary:hover,
  .image-testimonial--blue-secondary .button.button--green.button--secondary:focus,
  .image-testimonial--blue-secondary .button.button--green.button--secondary:hover {
    background-color: #CCC;
    border-color: #CCC; }
    .image-testimonial--blue .button.button--blue.button--secondary:focus span, .image-testimonial--blue .button.button--blue.button--secondary:hover span,
    .image-testimonial--blue .button.button--white.button--secondary:focus span,
    .image-testimonial--blue .button.button--white.button--secondary:hover span,
    .image-testimonial--blue .button.button--green.button--secondary:focus span,
    .image-testimonial--blue .button.button--green.button--secondary:hover span,
    .image-testimonial--blue-secondary .button.button--blue.button--secondary:focus span,
    .image-testimonial--blue-secondary .button.button--blue.button--secondary:hover span,
    .image-testimonial--blue-secondary .button.button--white.button--secondary:focus span,
    .image-testimonial--blue-secondary .button.button--white.button--secondary:hover span,
    .image-testimonial--blue-secondary .button.button--green.button--secondary:focus span,
    .image-testimonial--blue-secondary .button.button--green.button--secondary:hover span {
      color: #1A1A1A; }
    .image-testimonial--blue .button.button--blue.button--secondary:focus svg *, .image-testimonial--blue .button.button--blue.button--secondary:focus svg .flogas-icon--foreground, .image-testimonial--blue .button.button--blue.button--secondary:hover svg *, .image-testimonial--blue .button.button--blue.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--blue .button.button--white.button--secondary:focus svg *,
    .image-testimonial--blue .button.button--white.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--blue .button.button--white.button--secondary:hover svg *,
    .image-testimonial--blue .button.button--white.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--blue .button.button--green.button--secondary:focus svg *,
    .image-testimonial--blue .button.button--green.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--blue .button.button--green.button--secondary:hover svg *,
    .image-testimonial--blue .button.button--green.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--blue-secondary .button.button--blue.button--secondary:focus svg *,
    .image-testimonial--blue-secondary .button.button--blue.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--blue-secondary .button.button--blue.button--secondary:hover svg *,
    .image-testimonial--blue-secondary .button.button--blue.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--blue-secondary .button.button--white.button--secondary:focus svg *,
    .image-testimonial--blue-secondary .button.button--white.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--blue-secondary .button.button--white.button--secondary:hover svg *,
    .image-testimonial--blue-secondary .button.button--white.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--blue-secondary .button.button--green.button--secondary:focus svg *,
    .image-testimonial--blue-secondary .button.button--green.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--blue-secondary .button.button--green.button--secondary:hover svg *,
    .image-testimonial--blue-secondary .button.button--green.button--secondary:hover svg .flogas-icon--foreground {
      fill: #1A1A1A; }

.image-testimonial--black .button.button--purple,
.image-testimonial--black .button.button--blue,
.image-testimonial--black .button.button--white,
.image-testimonial--black .button.button--green {
  background-color: #FFF;
  border-color: #FFF; }
  .image-testimonial--black .button.button--purple .button__inner span,
  .image-testimonial--black .button.button--blue .button__inner span,
  .image-testimonial--black .button.button--white .button__inner span,
  .image-testimonial--black .button.button--green .button__inner span {
    color: #1A1A1A; }
  .image-testimonial--black .button.button--purple .button__inner svg *, .image-testimonial--black .button.button--purple .button__inner svg .flogas-icon--foreground,
  .image-testimonial--black .button.button--blue .button__inner svg *,
  .image-testimonial--black .button.button--blue .button__inner svg .flogas-icon--foreground,
  .image-testimonial--black .button.button--white .button__inner svg *,
  .image-testimonial--black .button.button--white .button__inner svg .flogas-icon--foreground,
  .image-testimonial--black .button.button--green .button__inner svg *,
  .image-testimonial--black .button.button--green .button__inner svg .flogas-icon--foreground {
    fill: #1A1A1A; }
  .image-testimonial--black .button.button--purple:focus, .image-testimonial--black .button.button--purple:hover, .image-testimonial--black .button.button--purple:before,
  .image-testimonial--black .button.button--blue:focus,
  .image-testimonial--black .button.button--blue:hover,
  .image-testimonial--black .button.button--blue:before,
  .image-testimonial--black .button.button--white:focus,
  .image-testimonial--black .button.button--white:hover,
  .image-testimonial--black .button.button--white:before,
  .image-testimonial--black .button.button--green:focus,
  .image-testimonial--black .button.button--green:hover,
  .image-testimonial--black .button.button--green:before {
    background-color: #CCC;
    color: #1A1A1A; }
  .image-testimonial--black .button.button--purple.button--secondary .button__inner,
  .image-testimonial--black .button.button--blue.button--secondary .button__inner,
  .image-testimonial--black .button.button--white.button--secondary .button__inner,
  .image-testimonial--black .button.button--green.button--secondary .button__inner {
    color: #FFF; }
    .image-testimonial--black .button.button--purple.button--secondary .button__inner span,
    .image-testimonial--black .button.button--blue.button--secondary .button__inner span,
    .image-testimonial--black .button.button--white.button--secondary .button__inner span,
    .image-testimonial--black .button.button--green.button--secondary .button__inner span {
      color: #FFF; }
    .image-testimonial--black .button.button--purple.button--secondary .button__inner svg *, .image-testimonial--black .button.button--purple.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--black .button.button--blue.button--secondary .button__inner svg *,
    .image-testimonial--black .button.button--blue.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--black .button.button--white.button--secondary .button__inner svg *,
    .image-testimonial--black .button.button--white.button--secondary .button__inner svg .flogas-icon--foreground,
    .image-testimonial--black .button.button--green.button--secondary .button__inner svg *,
    .image-testimonial--black .button.button--green.button--secondary .button__inner svg .flogas-icon--foreground {
      fill: #FFF; }
  .image-testimonial--black .button.button--purple.button--secondary:after,
  .image-testimonial--black .button.button--blue.button--secondary:after,
  .image-testimonial--black .button.button--white.button--secondary:after,
  .image-testimonial--black .button.button--green.button--secondary:after {
    border-color: #FFF; }
  .image-testimonial--black .button.button--purple.button--secondary:before,
  .image-testimonial--black .button.button--blue.button--secondary:before,
  .image-testimonial--black .button.button--white.button--secondary:before,
  .image-testimonial--black .button.button--green.button--secondary:before {
    background-color: #CCC; }
  .image-testimonial--black .button.button--purple.button--secondary:focus, .image-testimonial--black .button.button--purple.button--secondary:hover,
  .image-testimonial--black .button.button--blue.button--secondary:focus,
  .image-testimonial--black .button.button--blue.button--secondary:hover,
  .image-testimonial--black .button.button--white.button--secondary:focus,
  .image-testimonial--black .button.button--white.button--secondary:hover,
  .image-testimonial--black .button.button--green.button--secondary:focus,
  .image-testimonial--black .button.button--green.button--secondary:hover {
    background-color: #CCC;
    border-color: #CCC; }
    .image-testimonial--black .button.button--purple.button--secondary:focus span, .image-testimonial--black .button.button--purple.button--secondary:hover span,
    .image-testimonial--black .button.button--blue.button--secondary:focus span,
    .image-testimonial--black .button.button--blue.button--secondary:hover span,
    .image-testimonial--black .button.button--white.button--secondary:focus span,
    .image-testimonial--black .button.button--white.button--secondary:hover span,
    .image-testimonial--black .button.button--green.button--secondary:focus span,
    .image-testimonial--black .button.button--green.button--secondary:hover span {
      color: #1A1A1A; }
    .image-testimonial--black .button.button--purple.button--secondary:focus svg *, .image-testimonial--black .button.button--purple.button--secondary:focus svg .flogas-icon--foreground, .image-testimonial--black .button.button--purple.button--secondary:hover svg *, .image-testimonial--black .button.button--purple.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--black .button.button--blue.button--secondary:focus svg *,
    .image-testimonial--black .button.button--blue.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--black .button.button--blue.button--secondary:hover svg *,
    .image-testimonial--black .button.button--blue.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--black .button.button--white.button--secondary:focus svg *,
    .image-testimonial--black .button.button--white.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--black .button.button--white.button--secondary:hover svg *,
    .image-testimonial--black .button.button--white.button--secondary:hover svg .flogas-icon--foreground,
    .image-testimonial--black .button.button--green.button--secondary:focus svg *,
    .image-testimonial--black .button.button--green.button--secondary:focus svg .flogas-icon--foreground,
    .image-testimonial--black .button.button--green.button--secondary:hover svg *,
    .image-testimonial--black .button.button--green.button--secondary:hover svg .flogas-icon--foreground {
      fill: #1A1A1A; }

.image-testimonial--h-left .image-testimonial__background-image {
  background-position-x: left; }

.image-testimonial--h-center .image-testimonial__background-image {
  background-position-x: center; }

.image-testimonial--h-right .image-testimonial__background-image {
  background-position-x: right; }

.image-testimonial--v-top .image-testimonial__background-image {
  background-position-y: top; }

.image-testimonial--v-middle.image-testimonial__background-image {
  background-position-y: center; }

.image-testimonial--v-bottom .image-testimonial__background-image {
  background-position-y: bottom; }

.image-testimonial--swoosh-0 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  display: none; }

.image-testimonial--swoosh-2 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  fill: #A590BB;
  border-color: #A590BB; }

.image-testimonial--swoosh-3 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  fill: #00B3CF;
  border-color: #00B3CF; }

.image-testimonial--blue.image-testimonial--swoosh-2 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  fill: #99E0EB;
  border-color: #99E0EB; }

.image-testimonial--blue.image-testimonial--swoosh-3 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  fill: #4C2177;
  border-color: #4C2177; }

.image-testimonial--green-dark.image-testimonial--swoosh-2 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  fill: #57A173;
  border-color: #57A173; }

.image-testimonial--green-dark.image-testimonial--swoosh-3 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  fill: #78BE21;
  border-color: #78BE21; }

.image-testimonial--blue-secondary.image-testimonial--swoosh-2 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  fill: #C2EBF8;
  border-color: #C2EBF8; }

.image-testimonial--blue-secondary.image-testimonial--swoosh-3 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  fill: #4C2177;
  border-color: #4C2177; }

.image-testimonial--black.image-testimonial--swoosh .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
  fill: #191919;
  border-color: #191919; }

.image-testimonial-special-offer {
  padding: 32px 0; }
  @media (min-width: 768px) {
    .image-testimonial-special-offer {
      transform: translate(0, -50%);
      position: absolute;
      left: 240px;
      top: 50%; } }
  @media (min-width: 992px) and (max-width: 1283.98px) {
    .image-testimonial-special-offer {
      left: 220px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .image-testimonial-special-offer {
      left: 220px; } }
  @media (max-width: 767.98px) {
    .image-testimonial-special-offer {
      text-align: center;
      padding: 16px 0; } }
  .image-testimonial-special-offer .image-testimonial-special-offer__image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    max-height: 220px; }
    @media (max-width: 1283.98px) {
      .image-testimonial-special-offer .image-testimonial-special-offer__image {
        max-width: 50%; } }
    @media (max-width: 767.98px) {
      .image-testimonial-special-offer .image-testimonial-special-offer__image {
        max-height: 180px; } }
  .image-testimonial-special-offer .image-testimonial-special-offer__roundal {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #FFF;
    border-radius: 50%;
    position: relative;
    width: 116px;
    height: 116px;
    margin-left: 28px; }
    @media (max-width: 1283.98px) {
      .image-testimonial-special-offer .image-testimonial-special-offer__roundal {
        width: 100px;
        height: 100px; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .image-testimonial-special-offer .image-testimonial-special-offer__roundal {
        margin-top: 20px; } }
    .image-testimonial-special-offer .image-testimonial-special-offer__roundal-inner {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      font-size: 13px; }
      @media (max-width: 1283.98px) {
        .image-testimonial-special-offer .image-testimonial-special-offer__roundal-inner {
          font-size: 12px; } }
      .image-testimonial-special-offer .image-testimonial-special-offer__roundal-inner strong {
        white-space: nowrap;
        font-weight: 600;
        display: block;
        font-size: 22px; }
        @media (max-width: 1283.98px) {
          .image-testimonial-special-offer .image-testimonial-special-offer__roundal-inner strong {
            font-size: 18px; } }
    .image-testimonial-special-offer .image-testimonial-special-offer__roundal--purple {
      background: #4C2177; }
    .image-testimonial-special-offer .image-testimonial-special-offer__roundal--blue {
      background: #00B3CF; }
    .image-testimonial-special-offer .image-testimonial-special-offer__roundal--pink {
      background: #E0004D; }
    .image-testimonial-special-offer .image-testimonial-special-offer__roundal--green-dark {
      background: #007A33; }
    .image-testimonial-special-offer .image-testimonial-special-offer__roundal--blue-secondary {
      background: #00A9E0; }
    .image-testimonial-special-offer .image-testimonial-special-offer__roundal--black {
      background: #040505; }
