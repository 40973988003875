$button-padding: 10px 26px;
$button-height: 48px;
$button-border-radius: $button-height / 2;
@mixin button() {
  display: inline-block;
  padding: $button-padding;
  min-height: $button-height;
  line-height: $button-height / 1.75;
  font-weight: $medium;
  border-radius: $button-border-radius;
  text-align: center;
  transition: color ease-in-out 0.2s, background-color 0.5s ease-in, border-color 0.35s ease-in;
  position: relative;
  overflow: hidden;
  background-color: $purple;
  color: $white;
  @include media-breakpoint-down(md) {
    padding: 10px 24px;
  }
  @include media-breakpoint-down(sm) {
    padding: 10px $space;
  }
  @include media-breakpoint-down(xsm) {
    font-size: 14px;
  }
  &.wide, &.button--align-wide {
    min-width: 100%;
    display: block;
  }
  span.button__inner {
    position: relative;
    z-index: 2;
    span {
      transition: color ease-in-out 0.5s;
    }
  }
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: height ease-in-out 0.4s;
    border-radius: 50% 50% 0 0/75% 75% 0 0;
    background-color: $purple-700;
  }
  &:focus,
  &:hover {
    background-color: $purple-700;
    color: $white;

    &:before {
      height: 300%;
    }
  }
  svg, .editor-rich-text, span {
    display: inline-block;
  }
  svg {
    height: 24px;
    width: 24px;
    margin-right: $space / 2.66666667;
    path, *, .flogas-icon--foreground {
      transition: fill ease-in-out 0.2s;
      fill: $white;
    }
  }
  .flogas-icon--background {
    display: none;
  }
  &.button--secondary {
    background-color: transparent;
  }
  @include media-breakpoint-down(xsm) {
    width: 100%;
    display: block;
    padding-left: $space-2 / 1.25;
    padding-right: $space-2 / 1.25;
  }
  &:not([href]) {
    color: $white;
  }
}
@mixin buttonPurple() {
  background-color: $purple;
  .button__inner {
    span {
      color: $white;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $white;
    }
  }
  &:focus,
  &:hover,
  &:before {
    background-color: $purple-700;
    color: $white;
  }
}
@mixin buttonBlue() {
  background-color: $blue;
  .button__inner {
    span {
      color: $white;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $white;
    }
  }
  &:focus,
  &:hover,
  &:before {
    background-color: $blue-500;
    color: $white;
  }
}
@mixin buttonGray() {
  background-color: $grey-800;
  .button__inner {
    span {
      color: $white;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $white;
    }
  }
  &:focus,
  &:hover,
  &:before {
    background-color: $grey-500;
    color: $white;
  }
}
@mixin buttonGreen() {
  background-color: $green;
  .button__inner {
    span {
      color: $white;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $white;
    }
  }
  &:focus,
  &:hover,
  &:before {
    background-color: $green-100;
    color: $white;
  }
}
@mixin buttonWhite() {
  background-color: $white;
  border-color: $white;
  .button__inner {
    span {
      color: $black;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $black;
    }
  }

  &:focus,
  &:hover,
  &:before {
    background-color: $grey-200;
    color: $black;
  }
}
@mixin buttonSecondary() {
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid $purple;
    border-radius: $button-border-radius;
  }
  color: $purple;
  &:not([href]) {
    color: $purple;
  }
  svg path, svg *, svg .flogas-icon--foreground {
    fill: $purple;
  }
  &:focus,
  &:hover {
    background-color: $purple-700;
    border-color: $purple-700;
    color: $white;

    svg path, svg *, svg .flogas-icon--foreground {
      fill: $white;
    }
  }
  &:before {
    background-color: $purple-700;
  }
  &:not([href]):hover {
    color: $white;
  }
}
@mixin buttonSecondaryPurple() {
  .button__inner {
    color: $purple;
    span {
      color: $purple;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $purple;
    }
  }
  &:after {
    border-color: $purple;
  }
  &:before {
    background-color: $purple;
  }

  &:focus,
  &:hover {
    span {
      color: $white;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $white;
    }
    background-color: $purple;
  }
}

@mixin buttonSecondaryBlue() {
  .button__inner {
    color: $blue;
    span {
      color: $blue;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $blue;
    }
  }
  &:after {
    border-color: $blue;
  }
  &:before {
    background-color: $blue-500;
  }

  &:focus,
  &:hover {
    span {
      color: $white;
    }
    svg *, svg .flogas-icon--foreground {
      fill: $white;
    }
    background-color: $blue-500;
    border-color: $blue-500;
  }
}

@mixin buttonSecondaryGreen() {
  .button__inner {
    color: $green;
    span {
      color: $green;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $green;
    }
  }
  &:after {
    border-color: $green;
  }
  &:before {
    background-color: $green-100;
  }

  &:focus,
  &:hover {
    span {
      color: $white;
    }
    svg *, svg .flogas-icon--foreground {
       fill: $white;
     }
    background-color: $green-100;
    border-color: $green-100;
  }
}
@mixin buttonSecondaryWhite() {
  .button__inner {
    color: $white;
    span {
      color: $white;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $white;
    }
  }
  &:after {
    border-color: $white;
  }
  &:before {
    background-color: $grey-200;
  }

  &:focus,
  &:hover {
    background-color: $grey-200;
    border-color: $grey-200;
    span {
      color: $black;
    }
    svg *, svg .flogas-icon--foreground {
      fill: $black;
    }
  }
}
@mixin buttonSecondaryWhiteBlackText() {
  .button__inner {
    color: $black;
    span {
      color: $black;
    }

    svg *, svg .flogas-icon--foreground {
      fill: $black;
    }
  }
  &:after {
    border-color: $white;
  }
  &:before {
    background-color: $grey-200;
  }

  &:focus,
  &:hover {
    background-color: $grey-200;
    border-color: $grey-200;
    span {
      color: $black;
    }
    svg *, svg .flogas-icon--foreground {
      fill: $black;
    }
  }
}