// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.
// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);
  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);
  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

$screen-xs-min: 321px !default;
$screen-sm-min: 768px !default;
$screen-md-min: 992px !default;
$screen-lg-min: 1200px !default;
$in_size_xs: $screen-xs-min !default;
$in_size_sm: $screen-sm-min !default;
$in_size_md: $screen-md-min !default;
$in_size_lg: $screen-lg-min !default;
$combine_adjacent_sizes: false !default;

@function get_next_breakpoint($width) {
  @if $width == xs {
    @return sm;
  }
  @if $width == sm {
    @return md;
  }
  @if $width == md {
    @return lg;
  }
  @return null;
}
@function get_min_width($width) {
  @if $width == xxs {
    @return null;
  }
  @if $width == xs {
    @return $in_size_xs;
  }
  @if $width == sm {
    @return $in_size_sm;
  }
  @if $width == md {
    @return $in_size_md;
  }
  @if $width == lg {
    @return $in_size_lg;
  }
  @if type_of($width) == number {
    @return $width;
  }
  @return null;
}
@function get_max_width($width) {
  @if $width == xxs {
    @return $in_size_xs - 1px;
  }
  @if $width == xs {
    @return $in_size_sm - 1px;
  }
  @if $width == sm {
    @return $in_size_md - 1px;
  }
  @if $width == md {
    @return $in_size_lg - 1px;
  }
  @return null;
}
@mixin in_size_bounds($min_width, $max_width) {
  @if $min_width != null {
    @if $max_width != null {
      @media (min-width: $min_width) and (max-width: $max_width) {
        @content;
      }
    } @else {
      @media (min-width: $min_width) {
        @content;
      }
    }
  } @else {
    @if $max_width != null {
      @media (max-width: $max_width) {
        @content;
      }
    }
  }
}
@mixin in_size($widths) {
  $loop_each: true;
  @if length($widths) > 1 {
    $loop_each: $combine_adjacent_sizes == false;
    $prev_w: 1;
    @for $w from 2 to length($widths) + 1 {
      $prev_breakpoint: nth($widths, $prev_w);
      $this_breakpoint: nth($widths, $w);
      @if get_next_breakpoint($prev_breakpoint) != $this_breakpoint {
        $loop_each: true;
        $this_min: get_min_width($this_breakpoint);
        $prev_min: get_min_width($prev_breakpoint);
        @if $combine_adjacent_sizes and ( $this_min == null or ($prev_min != null and ($this_min < $prev_min)) ) {
          @if length($widths) == 2 {
            @warn "@include in_size((" + $widths + ")) should be @include in_size((" + $this_breakpoint + ", " + $prev_breakpoint + "))";
          } @else {
            @if $prev_w == 1 {
              @warn "@include in_size((" + $widths + ")) should be re-ordered to @include in_size((" + $this_breakpoint + ", " + $prev_breakpoint + ", ...))";
            } @else {
              @warn "@include in_size((" + $widths + ")) should be re-ordered to @include in_size((..., " + $this_breakpoint + ", " + $prev_breakpoint + "))";
            }
          }
        }
      }
      $prev_w: $w;
    }
    @if $loop_each == false {
      @include in_size_bounds(get_min_width(nth($widths, 1)), get_max_width(nth($widths, length($widths)))) {
        @content;
      }
    }
  }
  @if $loop_each {
    @each $width in $widths {
      @include in_size_bounds(get_min_width($width), get_max_width($width)) {
        @content;
      }
    }
  }
}
@mixin breakpoints($property, $lg, $md, $sm, $xs) {
  @include in_size(lg) {
    #{$property}: $lg;
  }
  @include in_size(md) {
    #{$property}: $md;
  }
  @include in_size(sm) {
    #{$property}: $sm;
  }
  @include in_size(xs) {
    #{$property}: $xs;
  }
}