@import '../../config/config';

.image-testimonial {
    width: 100%;
    position: relative;
    background: $purple;
    overflow: hidden;
    @include media-breakpoint-down(md) {
        min-height: 150px;
    }
    
    &.image-testimonial--module {
        margin-bottom: $space-4;
        @include media-breakpoint-down(md) {
            margin-bottom: $space-2;
        }
        @media screen and (max-height: 768px) {
            margin-bottom: $space-2;
        }
    }
    
    &__quote {
        color: $grey-900;
        
        &-text {
            font-size: 24px;
            line-height: 36px;
            font-weight: 500;
            font-style: italic;
            max-width: 466px;
            
            p {
                &:first-of-type {
                    &:before {
                        content: "“";
                        display: inline-block;
                    }
                }
                &:last-of-type {
                    &:after {
                        content: "”";
                        display: inline-block;
                    }
                }
            }
        }
        
        &-author {
            font-size: 16px;
            line-height: 24px;
            color: $black;
            font-weight: 600;
            
            &:before {
                content: "";
                display: block;
                width: 64px;
                height: 1px;
                background: $grey-900;
                margin-bottom: 24px;
                margin-top: 24px;
            }
        }
    }
}

//Only applied to frontend.
#flogas-content {
    .image-testimonial {
        width: 100vw;
        left: 50%;
        transform: translatex(-50%);
    }
}

.image-testimonial__background {
    width: 100%;
    margin: auto;
    //max-width: 1920px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 0);
    @include media-breakpoint-down(sm) {
        min-height: 220px;
    }
}

.image-testimonial__background-image {
    text-align: left;
    position: relative;
    height: 100%;
    margin-left: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 60%;
        background: linear-gradient(90deg, rgba(255, 255, 255, .9) 0, rgba(255, 255, 255, .6) 60%, rgba(255, 255, 255, 0) 100%);
        
        @include media-breakpoint-down(md) {
            width: 100%;
            background: rgba(255, 255, 255, .8);
        }
    }
}

.image-testimonial__inner {
    position: relative;
    z-index: 2;
    overflow: hidden;
    color: $white;
    
    .link-inline, a:not(.button) {
        color: $white;
        transition: all ease-in-out 0.2s;
        
        &:after {
            transition: all ease-in-out 0.2s;
        }
        
        &:hover {
            opacity: 0.7;
        }
    }
    
    h1, h2, h3, h4, h5, h6, span.small {
        margin: 0 0 16px 0;
        color: $white;
    }
    
    h4, h5, h6 {
        font-weight: 400;
    }
    
    @include make-container();
    @include make-container-max-widths();
    
    h1, span.small {
        font-size: 1.5rem;
    }
    
    h2, h1.large {
        font-weight: 500;
        font-size: 2.5rem;
        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
    }
    
    @include media-breakpoint-up(md) {
        min-height: 200px;
        z-index: 3;
    }
    @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: none;
        padding: 20px;
    }
    @include media-breakpoint-down(xsm) {
        padding: 20px 22.5px;
    }
    @media screen and (max-height: 768px) {
        padding-bottom: $space-2;
    }
}

.image-testimonial__inner-content {
    margin: $space 0 $space-2 0;
    @include media-breakpoint-up(md) {
        width: 60%;
    }
    @include media-breakpoint-only(lg) {
        margin: 80px 0;
        padding-right: 150px;
    }
    @include media-breakpoint-only(md) {
        margin: 62px 0;
        width: 60%;
        &.has-breadcrumbs {
            margin-top: 55px;
        }
    }
    @include media-breakpoint-only(xl) {
        margin: 81px 0 80px 0;
    }
    @include in_size((xxs, xs)) {
        max-width: 510px;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-height: 768px) {
        margin: $space-2 0;
    }
    
    > p > a {
        @include link;
        @include linkWhite;
    }
    
    .breadcrumbs {
        margin-bottom: 20px;
        border: none;
        
        .container {
            padding: 0;
        }
        
        li {
            color: rgba(#fff, 0.5);
            
            a {
                color: #fff;
            }
        }
    }
    
    .flogas-columns {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        width: auto;
        left: auto;
        right: auto;
    }
    
    > div, > p {
        margin-bottom: 25px;
    }
    
    .wp-block-flogas-buttons {
        margin-bottom: 0px;
        
        .wp-block-flogas-button {
            padding-bottom: 15px;
        }
    }
    
    > *:last-child {
        margin-bottom: 1px;
    }
}


// modifiers -- Text modifiers are in base/_typography-inheritance.scss
.image-testimonial--blue,
.image-testimonial--blue-secondary,
.image-testimonial--green-dark,
.image-testimonial--black,
.image-testimonial--purple {
    @include media-breakpoint-down(sm) {
        .image-testimonial__background {
            position: static;
            transform: translate(0);
        }
    }
}

.image-testimonial {
    .button--white {
        @include buttonWhite();
        
        &.button--secondary {
            @include buttonSecondaryWhite();
        }
    }
}

.image-testimonial--purple {
    .image-testimonial__background {
        background: $purple;
    }
    
    .button.button--purple,
    .button.button--white,
    .button.button--green {
        @include buttonBlue();
        
        &.button--secondary {
            @include buttonSecondaryWhite();
        }
    }
    
    .has-purple-color {
        color: $purple-500;
    }
}

.image-testimonial--green-dark {
    background: $green-dark;
    
    .image-testimonial__background {
        background: $green-dark;
    }
    
    .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--mobile,
    .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--foreground {
        fill: $green-dark;
    }
    
    .button.button--purple,
    .button.button--white,
    .button.button--blue {
        @include buttonWhite();
        
        &.button--secondary {
            @include buttonSecondaryWhite();
        }
    }
    
    .has-green-dark-color {
        color: $green;
    }
}

.image-testimonial--blue {
    background: $blue;
    
    .image-testimonial__background {
        background: $blue;
    }
    
    .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--mobile,
    .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--foreground {
        fill: $blue;
    }
    
    .has-blue-color {
        color: $blue-400;
    }
}

.image-testimonial--blue-secondary {
    background: $blue-secondary;
    
    .image-testimonial__background {
        background: $blue-secondary;
    }
    
    .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--mobile,
    .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--foreground {
        fill: $blue-secondary;
    }
}

.image-testimonial--black {
    background: $hero-black;
    
    .image-testimonial__background {
        background: $hero-black;
    }
    
    .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--mobile,
    .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--foreground {
        fill: $hero-black;
    }
}

.image-testimonial--special-offer .image-testimonial__background-image {
    background: #FFF;
}

.image-testimonial--blue,
.image-testimonial--blue-secondary {
    .button.button--blue,
    .button.button--white,
    .button.button--green {
        @include buttonWhite();
        
        &.button--secondary {
            @include buttonSecondaryWhite();
        }
    }
}

.image-testimonial--black {
    .button.button--purple,
    .button.button--blue,
    .button.button--white,
    .button.button--green {
        @include buttonWhite();
        
        &.button--secondary {
            @include buttonSecondaryWhite();
        }
    }
}

.image-testimonial--h-left .image-testimonial__background-image {
    background-position-x: left;
}

.image-testimonial--h-center .image-testimonial__background-image {
    background-position-x: center;
}

.image-testimonial--h-right .image-testimonial__background-image {
    background-position-x: right;
}

.image-testimonial--v-top .image-testimonial__background-image {
    background-position-y: top;
}

.image-testimonial--v-middle.image-testimonial__background-image {
    background-position-y: center;
}

.image-testimonial--v-bottom .image-testimonial__background-image {
    background-position-y: bottom;
}

.image-testimonial--swoosh-0 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    display: none;
}

// Purple default
.image-testimonial--swoosh-2 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    fill: $purple-500;
    border-color: $purple-500;
}

.image-testimonial--swoosh-3 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    fill: $blue;
    border-color: $blue;
}

.image-testimonial--blue.image-testimonial--swoosh-2 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    fill: $blue-400;
    border-color: $blue-400;
}

.image-testimonial--blue.image-testimonial--swoosh-3 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    fill: $purple;
    border-color: $purple;
}

.image-testimonial--green-dark.image-testimonial--swoosh-2 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    fill: $green-100;
    border-color: $green-100;
}

.image-testimonial--green-dark.image-testimonial--swoosh-3 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    fill: $green;
    border-color: $green;
}

.image-testimonial--blue-secondary.image-testimonial--swoosh-2 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    fill: #C2EBF8;
    border-color: #C2EBF8;
}

.image-testimonial--blue-secondary.image-testimonial--swoosh-3 .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    fill: $purple;
    border-color: $purple;
}

.image-testimonial--black.image-testimonial--swoosh .image-testimonial__background-image > div .image-testimonial__swoosh.image-testimonial__swoosh--background {
    fill: $hero-black-secondary;
    border-color: $hero-black-secondary;
}

.image-testimonial-special-offer {
    padding: $space-2 0;
    @include media-breakpoint-up(md) {
        transform: translate(0, -50%);
        position: absolute;
        left: 240px;
        top: 50%;
    }
    @include media-breakpoint-only(lg) {
        left: 220px;
    }
    @include media-breakpoint-only(md) {
        left: 220px;
    }
    @include media-breakpoint-down(sm) {
        text-align: center;
        padding: $space 0;
    }
    
    .image-testimonial-special-offer__image {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        height: auto;
        max-height: 220px;
        @include media-breakpoint-down(lg) {
            max-width: 50%;
        }
        @include media-breakpoint-down(sm) {
            max-height: 180px;
        }
    }
    
    .image-testimonial-special-offer__roundal {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        color: #FFF;
        border-radius: 50%;
        position: relative;
        width: 116px;
        height: 116px;
        margin-left: 28px;
        @include media-breakpoint-down(lg) {
            width: 100px;
            height: 100px;
        }
        @include media-breakpoint-only(md) {
            margin-top: 20px;
        }
        
        &-inner {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 90%;
            transform: translate(-50%, -50%);
            font-size: 13px;
            @include media-breakpoint-down(lg) {
                font-size: 12px;
            }
            
            strong {
                white-space: nowrap;
                font-weight: 600;
                display: block;
                font-size: 22px;
                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                }
            }
        }
        
        &--purple {
            background: $purple;
        }
        
        &--blue {
            background: $blue;
        }
        
        &--pink {
            background: $pink;
        }
        
        &--green-dark {
            background: $green-dark;
        }
        
        &--blue-secondary {
            background: $blue-secondary;
        }
        
        &--black {
            background: $hero-black;
        }
    }
}